<template>
    <div v-loading.fullscreen.lock="loading">
        <bank-card-search-bar-component
            add_auth="新增银行卡"
            :Allstaff="Allstaff"
            @search="get_bank_card_index"
            @addTeam="addTeam"
        ></bank-card-search-bar-component>
        <common-table-component
            details_auth="银行卡详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_bank_card"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_bank_card_index"
        ></common-page-component>
        <bank-card-edit-component
            edit_auth="修改银行卡"
            del_auth="删除银行卡"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :bank_card_details_data="fbank_card_details_data"
            :Allstaff="Allstaff"
            @exitBankCardDialog="dialogExit"
            @search="get_bank_card_index"
            @show_edit="show_edit"
            @details_row="details_bank_card"
        ></bank-card-edit-component>
    </div>
</template>

<script>
import { bank_card_index_request,bank_card_details_request } from '@/network/finance/BankCard.js'
import { staff_list_request } from '@/network/list.js'

import BankCardSearchBarComponent from '@/components/finance/BackCard/BankCardSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import BankCardEditComponent from '@/components/finance/BackCard/BankCardEditComponent'

export default {
    name: 'BankCardView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                user_name: '',
                staff_id: '',
                type: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'type',
                    label: '类型',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'success',
                                filter_data: '私有'
                            }
                        } else if(d===1) {
                            return {
                                type: 'primary',
                                filter_data: '公共'
                            }
                        }
                    }
                },
                {
                    prop: 'user_name',
                    label: '户主',
                    minWidth: '200px'
                },
                {
                    prop: 'card',
                    label: '银行卡号',
                    minWidth: '200px'
                },
                {
                    prop: 'bank',
                    label: '开户行',
                    minWidth: '260px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '180px'
                }
            ],
            fbank_card_details_data: {},
            Allstaff: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_bank_card_index(param = {}) {
            this.loading = true
            this.cond.user_name = param.user_name ?? this.cond.user_name
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.type = param.type ?? this.cond.type
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            bank_card_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_bank_card(id) {
            this.fid = id
            this.fdialogFormVisible = true
            bank_card_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fbank_card_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_staff() {
            this.loading = true
            staff_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.Allstaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_bank_card_index()
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        BankCardSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        BankCardEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>